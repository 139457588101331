export default {
  title: "FAQ",
  subtitle: "Questions Fréquentes",
  questions: [
    {
      question: "Quels cours proposez-vous ?",
      answer:
        "Nous proposons une large gamme de cours en direct et interactifs en programmation et ingénierie logicielle. Les sujets incluent la conception d'algorithmes, la compréhension des bases de la programmation, le développement web, le développement mobile et le développement full-stack. Chaque cours est conçu pour aider les apprenants de tous niveaux, du débutant à l'expert.",
    },
    {
      question: "Ai-je besoin d'une expérience préalable pour rejoindre ?",
      answer:
        "Aucune expérience préalable n'est nécessaire ! Nos cours sont structurés pour convenir à tous les niveaux, des débutants complets aux programmeurs expérimentés souhaitant améliorer leurs compétences. Vous pouvez commencer à zéro, et nous vous guiderons pas à pas tout au long du processus d'apprentissage.",
    },
    {
      question: "Comment se déroulent les cours ?",
      answer:
        "Tous nos cours sont dispensés en ligne en direct par des instructeurs experts. Les sessions sont interactives, avec des projets pratiques et des retours en temps réel. Cela garantit que vous n'apprendrez pas seulement la théorie, mais que vous développerez également des compétences pratiques que vous pourrez appliquer immédiatement. Vous avez manqué une session ? Nous fournissons également des enregistrements pour que vous puissiez les revoir plus tard.",
    },
    {
      question: "Quels langages de programmation enseignez-vous ?",
      answer:
        "Nos cours couvrent une gamme de langages de programmation selon le cours spécifique. Nous proposons des cours dans des langages populaires tels que JavaScript, Python, HTML/CSS, React, et plus encore. Vous apprendrez à utiliser les bons outils et langages pour le développement web et mobile moderne.",
    },
    {
      question: "Comment puis-je m'inscrire à un cours ?",
      answer:
        "L'inscription est simple ! Il vous suffit de visiter notre site web, de parcourir les cours disponibles, et de choisir celui qui correspond à votre niveau et à vos intérêts. Après votre inscription, vous recevrez tous les détails nécessaires pour commencer. Si vous avez besoin d'aide pour choisir le bon cours, notre équipe de support est là pour vous aider.",
    },
    {
      question: "Fournissez-vous des certificats de cours ?",
      answer:
        "Oui, à la fin de nos cours, vous recevrez un certificat qui atteste de vos nouvelles compétences et connaissances. Cela peut être un atout précieux pour votre CV ou votre portfolio, montrant aux employeurs potentiels votre engagement envers l'apprentissage.",
    },
    {
      question: "Combien de temps durent les cours ?",
      answer:
        "La durée des cours varie en fonction du sujet. Certains cours sont intensifs et peuvent être complétés en quelques semaines, tandis que d'autres s'étendent sur plusieurs mois. Chaque page de cours sur notre site web détaille la durée et le temps de travail prévu.",
    },
    {
      question: "Que faire si j'ai des questions pendant le cours ?",
      answer:
        "Nos cours en direct sont très interactifs, ce qui signifie que vous pouvez poser des questions à tout moment pendant les sessions. De plus, nous offrons un support en dehors des cours via des forums ou des sessions individuelles avec des instructeurs pour vous assurer d'obtenir l'aide dont vous avez besoin.",
    },
  ],
};
