export default {
  title: "FAQ",
  subtitle: "Common Questions",
  questions: [
    {
      question: "What courses do you offer?",
      answer:
        "We offer a wide range of live, interactive courses in programming and software engineering. Topics include algorithm design, understanding programming fundamentals, web development, mobile development, and full-stack development. Each course is designed to help learners from beginner to advanced levels.",
    },
    {
      question: "Do I need prior experience to join?",
      answer:
        "No prior experience is required! Our courses are structured to accommodate all levels, from complete beginners to experienced programmers looking to enhance their skills. You can start from zero, and we'll guide you step by step through the learning process.",
    },
    {
      question: "How are the courses conducted?",
      answer:
        "All our courses are conducted live online by expert instructors. Sessions are interactive, with hands-on projects and real-time feedback. This ensures that you not only learn theory but also gain practical skills you can immediately apply. Miss a session? We also provide recordings for you to review later.",
    },
    {
      question: "What programming languages do you teach?",
      answer:
        "Our courses cover a range of programming languages depending on the specific course. We offer courses in popular languages like JavaScript, Python, HTML/CSS, React, and more. You'll learn to use the right tools and languages for modern web and mobile development.",
    },
    {
      question: "How can I enroll in a course?",
      answer:
        "Enrolling is easy! Simply visit our website, browse through the available courses, and select the one that suits your level and interest. After signing up, you'll receive all the necessary details to get started. If you need help choosing the right course, our support team is here to assist.",
    },
    {
      question: "Do you provide course certificates?",
      answer:
        "Yes, upon completion of our courses, you will receive a certificate that verifies your new skills and knowledge. This can be a valuable addition to your resume or portfolio, showing potential employers your dedication to learning.",
    },
    {
      question: "How long do the courses take?",
      answer:
        "Course durations vary depending on the subject matter. Some courses are intensive and may be completed in a few weeks, while others span several months. Each course page on our website details the duration and expected time commitment.",
    },
    {
      question: "What if I have questions during the course?",
      answer:
        "Our live courses are highly interactive, meaning you can ask questions at any time during the sessions. In addition, we offer support outside of class through forums or one-on-one sessions with instructors to ensure you get the help you need.",
    },
  ],
};
