export default {
  title: "FAQ",
  subtitle: "الأسئلة والأجوبة الشائعة",
  questions: [
    {
      question: "ما هي الدورات التي تقدمونها؟",
      answer:
        "نحن نقدم مجموعة واسعة من الدورات التفاعلية المباشرة في البرمجة والهندسة البرمجية. تشمل المواضيع تصميم الخوارزميات، فهم أساسيات البرمجة، تطوير الويب، تطوير التطبيقات المحمولة، وتطوير التطبيقات الكاملة (full-stack). تم تصميم كل دورة لمساعدة المتعلمين من المبتدئين إلى المتقدمين.",
    },
    {
      question: "هل أحتاج إلى خبرة مسبقة للانضمام؟",
      answer:
        "لا حاجة لخبرة مسبقة! دوراتنا مصممة لتناسب جميع المستويات، من المبتدئين الكاملين إلى المبرمجين ذوي الخبرة الذين يرغبون في تحسين مهاراتهم. يمكنك البدء من الصفر وسنرشدك خطوة بخطوة خلال عملية التعلم.",
    },
    {
      question: "كيف تُدار الدورات؟",
      answer:
        "جميع دوراتنا تُدار عبر الإنترنت بشكل مباشر بواسطة مدربين خبراء. الجلسات تفاعلية مع مشاريع عملية وتعليقات فورية، مما يضمن أنك لا تتعلم النظريات فقط بل تكتسب أيضًا مهارات عملية يمكنك تطبيقها على الفور. هل فاتتك جلسة؟ نحن نوفر أيضًا تسجيلات يمكنك مراجعتها لاحقًا.",
    },
    {
      question: "ما هي لغات البرمجة التي تقومون بتدريسها؟",
      answer:
        "تغطي دوراتنا مجموعة من لغات البرمجة حسب الدورة المحددة. نقدم دورات في لغات شهيرة مثل JavaScript وPython وHTML/CSS وReact وغيرهم. ستتعلم استخدام الأدوات واللغات المناسبة لتطوير الويب والتطبيقات المحمولة الحديثة.",
    },
    {
      question: "كيف يمكنني التسجيل في دورة؟",
      answer:
        "التسجيل سهل! فقط قم بزيارة موقعنا الإلكتروني، تصفح الدورات المتاحة، واختر الدورة التي تناسب مستواك واهتماماتك. بعد التسجيل، ستحصل على جميع التفاصيل اللازمة للبدء. إذا كنت بحاجة إلى مساعدة في اختيار الدورة المناسبة، فإن فريق الدعم لدينا هنا لمساعدتك.",
    },
    {
      question: "هل توفرون شهادات عند إكمال الدورات؟",
      answer:
        "نعم، عند الانتهاء من دوراتنا، ستحصل على شهادة تثبت مهاراتك ومعرفتك الجديدة. هذه الشهادة يمكن أن تكون إضافة قيمة إلى سيرتك الذاتية أو محفظتك، وتظهر التزامك بالتعلم لأصحاب العمل المحتملين.",
    },
    {
      question: "كم تستغرق مدة الدورات؟",
      answer:
        "تختلف مدة الدورات حسب الموضوع. بعض الدورات مكثفة ويمكن إكمالها في غضون أسابيع قليلة، بينما تستمر الأخرى لعدة أشهر. كل صفحة دورة على موقعنا توضح مدة الدورة والالتزام الزمني المتوقع.",
    },
    {
      question: "ماذا أفعل إذا كان لدي أسئلة أثناء الدورة؟",
      answer:
        "دوراتنا المباشرة تفاعلية للغاية، مما يعني أنه يمكنك طرح الأسئلة في أي وقت أثناء الجلسات. بالإضافة إلى ذلك، نقدم دعمًا خارج الحصص من خلال المنتديات أو جلسات فردية مع المدربين لضمان حصولك على المساعدة التي تحتاجها.",
    },
  ],
};
