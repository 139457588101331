export default {
  title: "FAQ",
  subtitle: "Häufige Fragen",
  questions: [
    {
      question: "Welche Kurse bieten Sie an?",
      answer:
        "Wir bieten eine breite Palette an interaktiven Live-Kursen in Programmierung und Softwareentwicklung an. Zu den Themen gehören Algorithmen, Programmiergrundlagen, Webentwicklung, mobile Entwicklung und Full-Stack-Entwicklung. Jeder Kurs ist darauf ausgelegt, Lernende von Anfängern bis hin zu Fortgeschrittenen zu unterstützen.",
    },
    {
      question: "Brauche ich Vorkenntnisse, um teilzunehmen?",
      answer:
        "Es sind keine Vorkenntnisse erforderlich! Unsere Kurse sind so strukturiert, dass sie für alle Niveaus geeignet sind, von völligen Anfängern bis hin zu erfahrenen Programmierern, die ihre Fähigkeiten verbessern möchten. Sie können bei Null anfangen, und wir begleiten Sie Schritt für Schritt durch den Lernprozess.",
    },
    {
      question: "Wie werden die Kurse durchgeführt?",
      answer:
        "Alle unsere Kurse werden live online von erfahrenen Dozenten durchgeführt. Die Sitzungen sind interaktiv, mit praktischen Projekten und Echtzeit-Feedback. Dies stellt sicher, dass Sie nicht nur Theorie lernen, sondern auch praktische Fähigkeiten erwerben, die Sie sofort anwenden können. Haben Sie eine Sitzung verpasst? Wir stellen auch Aufzeichnungen zur Verfügung, die Sie später ansehen können.",
    },
    {
      question: "Welche Programmiersprachen unterrichten Sie?",
      answer:
        "Unsere Kurse decken eine Reihe von Programmiersprachen ab, abhängig vom jeweiligen Kurs. Wir bieten Kurse in beliebten Sprachen wie JavaScript, Python, HTML/CSS, React und mehr an. Sie lernen, die richtigen Werkzeuge und Sprachen für die moderne Web- und mobile Entwicklung zu verwenden.",
    },
    {
      question: "Wie kann ich mich für einen Kurs anmelden?",
      answer:
        "Die Anmeldung ist einfach! Besuchen Sie einfach unsere Website, durchsuchen Sie die verfügbaren Kurse und wählen Sie den aus, der Ihrem Niveau und Ihren Interessen entspricht. Nach der Anmeldung erhalten Sie alle notwendigen Informationen, um loszulegen. Wenn Sie Hilfe bei der Auswahl des richtigen Kurses benötigen, steht Ihnen unser Support-Team zur Verfügung.",
    },
    {
      question: "Stellen Sie Kurszertifikate aus?",
      answer:
        "Ja, nach Abschluss unserer Kurse erhalten Sie ein Zertifikat, das Ihre neuen Fähigkeiten und Kenntnisse bestätigt. Dies kann eine wertvolle Ergänzung zu Ihrem Lebenslauf oder Portfolio sein und potenziellen Arbeitgebern Ihr Engagement für das Lernen zeigen.",
    },
    {
      question: "Wie lange dauern die Kurse?",
      answer:
        "Die Dauer der Kurse variiert je nach Thema. Einige Kurse sind intensiv und können in wenigen Wochen abgeschlossen werden, während andere mehrere Monate dauern. Auf jeder Kursseite auf unserer Website finden Sie Informationen zur Dauer und zum Zeitaufwand.",
    },
    {
      question: "Was passiert, wenn ich während des Kurses Fragen habe?",
      answer:
        "Unsere Live-Kurse sind sehr interaktiv, das bedeutet, dass Sie jederzeit während der Sitzungen Fragen stellen können. Darüber hinaus bieten wir Unterstützung außerhalb des Unterrichts über Foren oder Einzelgespräche mit Dozenten an, um sicherzustellen, dass Sie die Hilfe erhalten, die Sie benötigen.",
    },
  ],
};
